import Text from '../../../common/Text';

const PLANS = [
    {
        id: 'plan-000',
        title: 'Комплект 1',
        features: [
            'Архітектурний розділ',
            'Генплан',
            'Конструктивний розділ',
        ],
        bonusFeatures: [
            // 'Ескізні наміри забудови',
        ],
        durationMonths: '1-2 місяці',
        priceUAH: 350,
    },
    {
        id: 'plan-001',
        title: 'Комплект 2',
        features: [
            'Архітектурний розділ',
            'Генплан',
            'Конструктивний розділ',
            'Внутрішнє водопостачання та водовідведення',
            'Електротехнічні рішення',
        ],
        bonusFeatures: [
            'Ескізні наміри забудови',
        ],
        durationMonths: '3-5 місяців',
        priceUAH: 500,
    },
    {
        id: 'plan-002',
        title: 'Комплект 3',
        features: [
            'Архітектурний розділ',
            'Генплан',
            'Конструктивний розділ',
            'Внутрішнє водопостачання та водовідведення',
            'Електротехнічні рішення',
            // 'Прорахунок кошторису на будівництво в нашій компанії',
            'Кошторисна документація',
        ],
        bonusFeatures: [
            'Ескізні наміри забудови',
        ],
        durationMonths: '4-6 місяців',
        priceUAH: 600,
    },
    {
        id: 'plan-003',
        title: 'Ескізні наміри забудови для отримання будівельного паспорту (без робочого проекту)',
        features: [],
        bonusFeatures: [],
        durationMonths: '1 місяць',
        priceUAH: 60,
    }
]

const PlansTable = () => {
    return (
        <main className={'plans-table'}>
            {
                PLANS.map((plan, index) => {
                    return (
                        <div key={plan.id} className={`plan ${index === PLANS.length - 1 ? 'last' : ''}`}>
                            <div
                                className={`plan-row border-bottom ${!plan.features.length ? 'no-body' : ''}`}>{plan.title}</div>
                            {
                                !!plan.features.length &&
                                <div className={'plan-row border-bottom body column'}>
                                    <>
                                        <div className={'features'}>
                                        {
                                            plan.features.map((feature, index) => {
                                                return (
                                                    <li key={`${plan.id}-feature-${index}`}>
                                                        <i className="fa-solid fa-check"></i>
                                                        {feature}
                                                    </li>
                                                )
                                            })
                                        }
                                        </div>
                                        {
                                            !!plan.bonusFeatures.length &&
                                            <div className={'bonuses'}>
                                                <Text highlight={'bonus:'}/>
                                                {
                                                    plan.bonusFeatures.map((feature, index) => {
                                                        return (
                                                            <li key={`${plan.id}-bonus-feature-${index}`}>
                                                                {'- ' + feature}
                                                            </li>
                                                        )
                                                    })
                                                }
                                            </div>
                                        }
                                    </>
                                </div>
                            }
                            <div className={'plan-row border-bottom'}>
                                <i className="fa-regular fa-clock"></i>
                                {plan.durationMonths}
                            </div>
                            <div className={'plan-row'}>{plan.priceUAH + ' грн/m2'}</div>
                        </div>
                    );
                })
            }
        </main>
    )
};

export default PlansTable;
