const Fade = ({ isDark }) => {
    const getFadeColor = () => {
        if (isDark) {
            return 'rgba(0, 0, 0, 0.8)'
        }
        return 'rgba(0, 0, 0, 0.5)'
    }
    return (
      <div className={'fade'} style={{ backgroundColor: getFadeColor() }}></div>
    );
}

export default Fade;
