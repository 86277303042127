import Page from '../common/Page';
import Text from '../common/Text';
import Texts from '../../texts/texts.json';
import Counters from '../common/Counters';

const GreetingsPage = ({ isCountersVisible, offset, onScrollToContacts }) => {
    return (
        <Page>
            <article className={'article'}>
                {
                    Texts.greetings_page.texts.map((item) => {
                        return (
                            <Text key={item.id} text={item.text} type={item.type}/>
                        )
                    })
                }
            </article>
            <Counters isCountersVisible={isCountersVisible} offsetY={offset} onScrollToContacts={onScrollToContacts} />
        </Page>
    );
}

export default GreetingsPage;
