import Page from '../../common/Page';
import Text from '../../common/Text';
import Texts from '../../../texts/texts.json';
import ServicesCard from './components/ServicesCard';

const ServicesPage = ({ isVisible, offset }) => {
    return (
        <Page>
            <main className={'center-content'}>
                <div className={'services'} />
                <article className={'article'}>
                    {
                        Texts.services_page.texts.map((item) => {
                            return (
                                <Text isVisible={isVisible} key={item.id} text={item.text} type={item.type} highlight={item.highlight}/>
                            )
                        })
                    }
                </article>
                <article className={'section-container'}>
                    {
                        Texts.services_page.cards.filter((item => !!item.id)).map((item, index) => {
                            return (
                                <ServicesCard
                                    index={index}
                                    isVisible={isVisible}
                                    key={item.id}
                                    text={item.text}
                                    highlight={item.highlight}
                                    imgSrc={require(`../../../assets/services/${item.imageSrc}`)}
                                    {...item}
                                />
                            )
                        })
                    }
                </article>
            </main>
        </Page>
    );
}

export default ServicesPage;
