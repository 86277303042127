const SliderControlsBar = ({ currentIndex, imagesCount, onMouseEnter, onMouseLeave, onClick }) => {
    const getActiveIndex = () => {
        if (!currentIndex) {
            return imagesCount - 2;
        }
        return currentIndex - 1;
    }

    const activeIndex = getActiveIndex();

    return (
        <div className={'slider-controls-bar'}>
            {
                Array.from({ length: imagesCount - 1 }).map((_, index) => {
                    return (
                        <div
                            onClick={() => onClick(index + 1)}
                            onMouseEnter={onMouseEnter}
                            onMouseLeave={onMouseLeave}
                            key={`${index}-slider-button`}
                            className={activeIndex === index ? 'slider-control-button active' : 'slider-control-button'}>

                        </div>
                    )
                })
            }
        </div>
    )
}

export default SliderControlsBar;
