import { useEffect, useState } from 'react';
import SliderControlsBar from './components/SliderControlsBar';
import Text, { TEXT_TYPES } from '../common/Text';
import Fade from '../common/Fade';

const IMAGES = [
    {
        id: '-000',
        src: require('../../assets/slider_4.jpg'),
        alt: 'Промислові об\'єкти - фабрики, склади, тощо'
    },
    {
        id: '000',
        src: require('../../assets/slider_1.jpg'),
        alt: 'Послуги Aрхітектора'
    },
    {
        id: '001',
        src: require('../../assets/slider_2.jpg'),
        alt: 'Індивідуальні житлові будинки (котеджі)'
    },
    {
        id: '002',
        src: require('../../assets/slider_3.jpg'),
        alt: 'Багатоквартирні будинки, таунхауси'
    },
    {
        id: '003',
        src: require('../../assets/slider_4.jpg'),
        alt: 'Промислові об\'єкти - фабрики, склади, тощо'
    }
]

let resizeTimeoutId = 0;
let lastResizeDate = 0;
let intervalId = 0;

const Slider = ({ offset }) => {
    const [images, setImages] = useState(IMAGES);
    const [index, setIndex] = useState(1);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [isTransition, setIsTransition] = useState(true);
    const [isSliding, setIsSliding] = useState(true);

    const translateX = -index * windowWidth;
    const isScrolled = offset > 100;
    const onWindowResize = () => {
        if (Date.now() - lastResizeDate > 200) {
            setWindowWidth(window.innerWidth);
            lastResizeDate = Date.now();
        } else {
            if (resizeTimeoutId) {
                clearTimeout(resizeTimeoutId);
            }
            resizeTimeoutId = setTimeout(() => {
                setWindowWidth(window.innerWidth);
                lastResizeDate = Date.now();
            }, 200);

        }
    }

    const handleInterval = () => {
        if (!isSliding) {
            return;
        }
        setIndex(prevIndex => {
            const isLast = prevIndex === images.length - 1;
            return isLast ? 0 : prevIndex + 1;
        });
    };

    const adjustIndex = () => {
        if (index === images.length - 1) {
            setTimeout(() => {
                setIsTransition(false);
                setIndex(0);
                setTimeout(() => {
                    setIsTransition(true);
                }, 300);
            }, 300);
        }
    }

    useEffect(() => {
        adjustIndex();
    }, [index]);

    useEffect(() => {
        window.addEventListener('resize', onWindowResize)
        intervalId = setInterval(handleInterval, 3000);
        return () => {
            clearInterval(intervalId);
        }
    }, [isSliding]);

    const stopSliding = () => {
        setIsSliding(false);
    }

    const startSliding = () => {
        setIsSliding(true);
    }

    const setManualIndex = (idx) => {
        setIndex(idx);
    }

    return (
        <div className={'slider'}>
            <div className={'slider-container'}
                 style={{
                     transform: `translateX(${translateX}px)`,
                     transition: isTransition ? 'transform 0.3s ease' : 'transform 0s ease'
                 }}
            >
                <div
                    className={'slider-images'}
                    style={{
                        transform: `translateY(${offset * 0.5}px)`
                    }}
                >
                    {
                        images.map((item) => {
                            return (
                                <div key={item.id}
                                     className={'slider-image-wrapper'}>
                                    <img
                                        alt={item.alt}
                                        src={item.src}
                                    />
                                    <Fade />
                                    <p className={`image-info ${isScrolled ? 'hidden' : ''}`}>{item.alt}</p>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            <div className={'slider-controls'}>
                <SliderControlsBar
                    onClick={setManualIndex}
                    onMouseEnter={stopSliding}
                    onMouseLeave={startSliding}
                    currentIndex={index}
                    imagesCount={images.length}
                />
            </div>
        </div>
    )
}

export default Slider;
