import Page from '../common/Page';
import Text from '../common/Text';
import Texts from '../../texts/texts.json';
import Counters from '../common/Counters';
import SectionCard from '../common/SectionCard';

const WhyPage = ({ isVisible, offset }) => {
    return (
        <Page>
            <main className={'center-content'}>
                <div className={'why'} />
                <article className={'article'}>
                    {
                        Texts.why_page.texts.map((item) => {
                            return (
                                <Text
                                    isVisible={isVisible}
                                    key={item.id}
                                    text={item.text}
                                    type={item.type}
                                    highlight={item.highlight}
                                />
                            )
                        })
                    }
                </article>
                <article className={'section-container'}>
                    {
                        Texts.why_page.cards.map((item, index) => {
                            return (
                                <SectionCard
                                    index={index}
                                    isVisible={isVisible}
                                    key={item.id}
                                    text={item.text}
                                    highlight={item.highlight}
                                    icon={item.icon}
                                    {...item}
                                />
                            )
                        })
                    }
                </article>
            </main>
        </Page>
    );
}

export default WhyPage;
