import Button from '../common/Button';
import { useState } from 'react';

const BRAND_NAME = 'AzexProject';
const HEADER_ITEMS = [
    {
        id: '000',
        text: 'Ціни',
        href: '/',
    },
    {
        id: '001',
        text: 'Послуги',
        href: '/services',
    },
    // {
    //     id: '002',
    //     text: 'Відгуки',
    //     href: '',
    // },
    {
        id: '003',
        text: 'Контакти',
        href: '',
    }
]

const Header = ({ isCollapsed, onScrollToContacts, onScrollToPrices }) => {
    const [isOpened, setIsOpened] = useState(false);

    return (
        <nav className={isCollapsed ? 'header collapsed' : 'header'}>
            <div className={'header-content'}>
                <div className={`header-logo ${isOpened ? 'opened' : ''}`}>
                    <a href={'/'}>{BRAND_NAME.toUpperCase()}</a>
                </div>
                <div className={`navigation-bar ${isOpened ? 'opened' : ''}`}>
                    <div className={`toggleBar ${isOpened ? 'opened' : ''} ${isCollapsed ? 'collapsed' : ''}`}
                         onClick={() => {
                             setIsOpened((prev) => {
                                 return !prev;
                             })
                         }}
                    >
                        <div className="toggleBarStick"></div>
                        <div className="toggleBarStick"></div>
                        <div className="toggleBarStick"></div>
                    </div>
                    <div className={'header-links'}>
                        {
                            HEADER_ITEMS.map((item) => {
                                if (item.id === '000' || item.id === '003') {
                                    return (
                                        <button
                                            key={item.id}
                                            className={'link'}
                                            onClick={item.id === '000' ? onScrollToPrices : onScrollToContacts}
                                        >
                                            <span className={'link-text'}>{item.text}</span>
                                        </button>
                                    )
                                }
                                return (
                                    <a
                                        key={item.id}
                                        href={item.id !== '003' && item.href}
                                        className={'link'}
                                        onClick={item.id === '003' ? onScrollToContacts : () => {}}
                                    >
                                        <span className={'link-text'}>{item.text}</span>
                                    </a>
                                )
                            })
                        }
                    </div>
                    <div className={'header-social'}></div>
                    <div className={'header-button'}>
                        <Button text={'Зв\'язатися'} onClick={onScrollToContacts}></Button>
                    </div>
                </div>
            </div>
        </nav>
    )
}

export default Header;
