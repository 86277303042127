import React, { memo, useEffect, useState } from 'react';
import Button from './Button';


const Counter = ({ count, text, offsetY }) => {
    return (
        <div className={'counter-container'}>
            <span className={'count-number'}>{count.toString()}</span>
            <span className={'count-text'}>{text}</span>
        </div>
    )
}

const COUNTERS = [
    {
        id: '000',
        count: 0,
        text: 'Років досвіду',
        finalCount: 12,
    },
    {
        id: '002',
        count: 0,
        text: 'Зроблено проектів',
        finalCount: 110,
    }
]

function Counters({ isCountersVisible, offsetY, onScrollToContacts }) {
    const [counters, setCounters] = useState(COUNTERS);
    useEffect(() => {
        if (isCountersVisible && !counters[0].count) {
            const intervalId = setInterval(() => {
                setCounters((prevCounters) => {
                    const newCounters = prevCounters.map((item, index) => {
                        const amountPerAction = item.finalCount / 800;
                        return {
                            ...item,
                            count: amountPerAction + item.count,
                        }
                    });
                    return newCounters;
                })
            }, 1);

            setTimeout(() => {
                setCounters((prevCounters) => {
                    return prevCounters.map((item) => {
                        return {
                            ...item,
                            count: item.finalCount,
                        }
                    })
                })
                clearInterval(intervalId)
            }, 3000);


        }

    }, [isCountersVisible]);

    return (
        <div className={'counters-section'} style={{
            backgroundPositionY: `${offsetY * 0.5}px`,
        }}>
            <div className={'counters'}>
                {
                    counters.map((item) => {
                        return (
                            <Counter key={item.id} count={Math.floor(item.count)} text={item.text}/>
                        )
                    })
                }
            </div>
            <div className={'counters-button'}>
            <Button text={'Замовити консультацію'} onClick={onScrollToContacts}></Button>
            </div>
        </div>
    );
}

export default memo(Counters);
