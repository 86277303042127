import React, { useEffect, useRef, useState } from 'react';
import { BrowserRouter, createBrowserRouter, Route, RouterProvider, Routes } from 'react-router-dom';
import MainScreen from './components/screens/main/MainScreen';
import ServicesScreen from './components/screens/services/ServicesScreen';

function App() {
    const [offset, setOffset] = useState(0);
    const [itemsInView, setItemsInView] = useState({
        counters: false,
        why: false,
        services: false,
    });
    const observerRef = useRef(null);
    const isHeaderCollapsed = offset > 200;
    const isParallax = window.innerWidth > 600;
    const parallaxOffset = isParallax ? offset : 0;

    const onScroll = () => {
        setOffset(window.scrollY);
    }

    useEffect(() => {
        document.addEventListener('scroll', onScroll);
        const intersectionNodes = {
            counters: document.querySelector('.counters'),
            why: document.querySelector('.why'),
            services: document.querySelector('.services'),
        }
        observerRef.current = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    if (Object.keys(itemsInView).some((item) => {
                        return item === entry.target.className;
                    })) {
                        setItemsInView((prev) => {
                            return {
                                ...prev,
                                [entry.target.className]: true,
                            }
                        });
                        observerRef.current.unobserve(intersectionNodes[entry.target.className]);
                    }
                }
            })
        }, {
            root: null,
            // Set the root margin to detect intersection before the target enters the viewport
            rootMargin: '0px',
            // Threshold is the percentage of the target's visibility needed to trigger the callback
            threshold: 0, // Change this value as needed
        });

        Object.keys(intersectionNodes).forEach((item) => {
            if (intersectionNodes[item]) {
                observerRef.current?.observe(intersectionNodes[item]);
            }
        })

        if (window.location.search === '?p=prices') {
            setTimeout(() => {
                onScrollToPrices();
            }, 200);
        }

        return () => {
            document.removeEventListener('scroll', onScroll);
        }
    }, []);

    const onScrollToContacts = () => {
        document.querySelector('.contacts')?.scrollIntoView({
            behavior: 'smooth'
        });
    }

    const onScrollToPrices = () => {
        const path = window.location.pathname;
        if (path === '/services') {
            window.location.href = '/?p=prices';
            setTimeout(() => {
                document.querySelector('.prices')?.scrollIntoView({
                    behavior: 'smooth'
                });
            })
        } else {
            document.querySelector('.prices')?.scrollIntoView({
                behavior: 'smooth'
            });
        }
    }

    return (
        <BrowserRouter>
            <Routes>
                <Route
                    path="/"
                    element={
                        <MainScreen
                            isHeaderCollapsed={isHeaderCollapsed}
                            itemsInView={itemsInView}
                            parallaxOffset={parallaxOffset}
                            onScrollToContacts={onScrollToContacts}
                            onScrollToPrices={onScrollToPrices}
                        />
                    }
                />
                <Route
                    path="/services"
                    element={
                        <ServicesScreen
                            isHeaderCollapsed={isHeaderCollapsed}
                            itemsInView={itemsInView}
                            parallaxOffset={parallaxOffset}
                            onScrollToContacts={onScrollToContacts}
                            onScrollToPrices={onScrollToPrices}
                        />
                    }
                />
            </Routes>
        </BrowserRouter>
    );
}

export default App;
