import Fade from '../../../common/Fade';
import { useEffect, useState } from 'react';

const ServicesCard = ({
                          isVisible,
                          index,
                          isActive,
                          onClick,
                          text,
                          text2,
                          text3,
                          text4,
                          text4_link,
                          text5,
                          text5_link,
                          text6,
                          highlight,
                          imgSrc,
                          isFullScreen,
                          isDetailsButton,
                      }) => {
    const [isCardVisible, setIsCardVisible] = useState(false);
    useEffect(() => {
        if (isVisible) {
            setTimeout(() => {
                setIsCardVisible(true);
            }, 200 * (index + 1));
        }
    }, [isVisible]);
    return (
        <div className={`services-card ${isFullScreen ? 'full-screen' : ''} ${isCardVisible ? 'visible' : ''}`}
             onClick={onClick}>
            <img alt={'Послуга проектної компанії'} src={imgSrc} className={'services-card-image'}></img>
            <div className={'blur-overlay'}></div>
            <h4 className={`services-card-title ${isActive ? 'white' : ''}`}>{highlight}</h4>
            <p className={'services-card-description'}>{text}</p>
            {
                !!text2 && <>
                    <p className={'services-card-description'}>{text2}</p>
                    <p className={'services-card-description'}>{text3}</p>
                    <p className={'services-card-description'}>{text4}<a className={'link text-highlight'}
                                                                         href={'#'}>{text4_link}</a></p>
                    <p className={'services-card-description'}>{text5}<a className={'link text-highlight'}
                                                                         href={'#'}>{text5_link}</a></p>
                    <p className={'services-card-description'}>{text6}</p>
                </>
            }
            <Fade isDark={!isActive}/>
            {
                !!isDetailsButton &&
                <div className={'details-button'}>
                    <span>Дізнатися більше</span>
                </div>
            }
        </div>
    );
}

export default ServicesCard;
