const Button = ({
                    href,
                    text,
                    onClick = () => {}
                }) => {
    return (
        <a href={href} className={'button'} onClick={onClick}>{text}</a>
    )
}

export default Button;
