import Page from '../../common/Page';
import Text, { TEXT_TYPES } from '../../common/Text';
import Texts from '../../../texts/texts.json';
import PlansTable from './components/PlansTable';
import PricesPage from '../PricesPage';

const PlansPage = () => {
    return (
        <Page className={'prices'}>
            <PricesPage/>
            <article className={'article'}>
                <Text text={Texts.plansPage.texts[0].text} type={TEXT_TYPES.header}/>
            </article>
            <PlansTable/>
        </Page>
    );
}

export default PlansPage;
