import Slider from '../slider/Slider';
import Fade from '../common/Fade';
import MainTitle from '../common/MainTitle';

const MainPage = ({ offset }) => {
    return (
        <main className={'main-page'}>
            <Slider offset={offset} />
            <MainTitle />
        </main>
    );
};

export default MainPage;
