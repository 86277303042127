import Page from '../common/Page';
import Text from '../common/Text';
import Texts from '../../texts/texts.json';
import Counters from '../common/Counters';
import SectionCard from '../common/SectionCard';

const PricesPage = ({ isVisible, offset }) => {
    return (
        <Page className={'prices-page'}>
            <main className={'center-content'}>
                <article className={'article'}>
                    {
                        Texts.prices.texts.map((item) => {
                            return (
                                <Text
                                    isVisible={isVisible}
                                    key={item.id}
                                    text={item.text}
                                    type={item.type}
                                    highlight={item.highlight}
                                    linkText={item.linkText}
                                    linkUrl={item.linkUrl}
                                />
                            )
                        })
                    }
                </article>
            </main>
        </Page>
    );
}

export default PricesPage;
