export const TEXT_TYPES = {
    header: 'header',
    subheader: 'subheader',
    article: 'article',

}

const Wrapper = ({ onClick, isVisible, type, children, isBold, isSmall }) => {
    if (type === TEXT_TYPES.header) {
        return (
            <h3 className={`text-header${isBold ? ' bold' : ''}${isVisible ? ' visible' : ''}${!!onClick ? ' clickable' : ''}`}>{children}</h3>
        )
    }

    if (type === TEXT_TYPES.subheader) {
        return (
            <h4 className={`text-subheader${isBold ? ' bold' : ''}${isVisible ? ' visible' : ''}${!!onClick ? ' clickable' : ''}`}>{children}</h4>
        )
    }

    return (
        <p className={`text-article${isSmall ? ' small' : ''}${isBold ? ' bold' : ''}${isVisible ? ' visible' : ''}${!!onClick ? ' clickable' : ''}`}>{children}</p>
    )
}

const Text = ({
                  onClick,
                  children,
                  highlight,
                  isBold,
                  isSmall,
                  isVisible = true,
                  prefix,
                  text,
                  type,
                  linkText,
              }) => {
    return (
        <Wrapper onClick={onClick} isVisible={isVisible} type={type} isBold={isBold} isSmall={isSmall}>
            {
                prefix && prefix
            }
            {
                !!highlight &&
                <span className={'text-highlight'}>{highlight}</span>
            }
            {text}
            {linkText}
            {children}
        </Wrapper>
    )
}

export default Text;
