import React, { memo, useEffect, useState } from 'react';
import Text, { TEXT_TYPES } from '../../common/Text';
import Page from '../../common/Page';
import Header from '../../header/Header';
import ServicesCard from '../../pages/services/components/ServicesCard';
import ContactsPage from '../../pages/contacts/ContactsPage';

const SERVICES = [
    {
        id: '000',
        highlight: 'Розробка проектної документації',
        description: 'Розробка всіх стадій проектної документації: Робочий проект, Проект та Робоча документація',
        text2: 'Розробка обʼєктів житлової, громадської та промислової забудови класів наслідків СС1, СС2, СС3.',
        text3: 'Проектування розділів: АР (АБ), КБ (КР), КМ, ГП, ТХ, ЕТР, ВК (ЗВК), КД та інші (є субпідряд).',
        text4: 'Ескізні наміри забудови для отримання',
        text4_link: 'Будівельного паспорту',
        text5: 'Містобудівний розрахунок для отримання',
        text5_link: 'Містобудівних умов та обмежень',
        text6: 'Реєстрація проектів на Порталі ЄДЕССБ',
        src: 'docs.jpg',
        type: 'article',
        isVisible: true,
    },
    {
        id: '001',
        title: 'Ескізні наміри забудови',
        description: 'Пакет документів і креслень для отримання будівельного паспорту.',
        src: 'docs.jpg',
        isDetailsButton: true,
        isVisible: true,

    },
    // {
    //     id: '002',
    //     title: 'Архітектурний розділ',
    //     description: 'Допоможемо вам побачити ваш майбутній будинок в об\'ємному зображенні.',
    //     src: '3d.jpg',
    //     isVisible: false,
    //
    // },
    // {
    //     id: '003',
    //     title: 'Конструктивний розділ',
    //     description: 'здійснюємо авторський нагляд за будівництвом, щоб воно велося згідно з проектом.',
    //     src: 'construction.jpg',
    //     isVisible: false,
    //
    // },
    {
        id: '004',
        title: 'Консультації',
        description: 'Відповімо на всі ваші запитання щодо проектування та будівництва.',
        src: 'consultation.jpg',
        isVisible: true,

    },
    {
        id: '005',
        title: 'Супровід будівництва',
        description: 'Здійснюємо авторський нагляд за будівництвом.',
        src: 'construction.jpg',
        isVisible: true,
    },
]

const SERVICES_DETAILS = {
    '000': {
        children: () => {
            return (<></>)
        }
    },    // '000': {
    //     children: () => {
    //         return (
    //             <>
    //                 <Text
    //                     highlight={'Робочий проект'}
    //                     text={' - це сукупність розділів і креслень з усіма матеріалами та ' +
    //                         'специфікаціями, по якому будівельники можуть побудувати будинок.'
    //                     }
    //                 /><br/>
    //
    //                 <Text text={'До робочого проекту входять наступні розділи:'}/>
    //                 <Text text={'1. Архітектурний розділ'} isBold/>
    //                 <Text text={'2. Конструктивний розділ'} isBold/>
    //                 <Text text={'3. Інженерні мережі (за домовленістю)'} isBold/><br/>
    //
    //                 <Text>
    //                     <b>1. Архітектурний розділ</b> - розробляється після погодження всіх планів поверхів, розміщення
    //                     меблів, сантехніки та устаткування, розміщення будинку на ділянці та інші габаритні і
    //                     конструктивні особливості.
    //                 </Text><br/>
    //
    //                 <Text>В <b>Архітектурний розділ</b> входять наступні креслення:</Text>
    //
    //                 <Text> – Загальні дані ( короткий опис про будинок – техніко-економічні показники, інформація про
    //                     основні
    //                     матеріали для будівництва)</Text>
    //
    //                 <Text> – Схема генерального плану території (розташування будинку на ділянці, під’їзд до будинку та
    //                     пішохідні шляхи)</Text>
    //
    //                 <Text> – Архітектурні плани поверхів, з розташуванням меблів</Text>
    //
    //                 <Text> – Архітектурні розрізи по будівлі.</Text>
    //
    //                 <Text>– Фасади з чотирьох сторін.</Text>
    //
    //                 <Text> – План даху.</Text>
    //
    //                 <Text> – Кладочні плани (потрібні для мурування стін та перегородок, розміри прорізів для вікон та
    //                     дверей,
    //                     специфікація цегли)</Text>
    //
    //                 <Text> – Розгортки вентиляційних та димових каналах (розміри вентиляційних та димових каналів,
    //                     висоти).</Text>
    //
    //                 <Text> – Експлікація підлог.</Text>
    //
    //                 <Text> – Специфікація дверних та віконних заповнень.</Text>
    //
    //                 <Text> – Паспорт опорядження фасадів.</Text>
    //
    //                 <Text> – Таблиця кольорів опорядження фасадів ( площі матеріалів та кількість декоративних
    //                     елементів)</Text>
    //                 <br/>
    //
    //                 <Text onClick={() => {}} highlight={'ЗРАЗОК АРХІТЕКТУРНОГО РОЗДІЛУ'}><br/><br/></Text>
    //
    //                 <Text>
    //                     <b>2. Конструктивний розділ</b> – визначає основні несучі конструктивні елементи будинку
    //                     (фундамент,
    //                     перекриття, перемички, дах і т.п.) з усім переліком матеріалів та специфікаціями.
    //                 </Text><br/>
    //
    //                 <Text>В <b>Конструктивний розділ</b> входять наступні креслення:</Text>
    //
    //                 <Text> – План фундаментів (розрізи, схеми армування, вузли, витрати бетону та арматури)</Text>
    //
    //                 <Text> – Креслення колон (розрізи, схеми армування, вузли, витрати бетону та арматури)</Text>
    //
    //                 <Text> – План перемичок (перетини, витрати бетону та арматури)</Text>
    //
    //                 <Text> – Перекриття (план розташування плит перекриття, план монолітного перекриття, розрізи,
    //                     специфікація
    //                     матеріалу)
    //                 </Text>
    //
    //                 <Text> – Сходи (опалубочний план сходів, розрізи, схеми армування, специфікація)</Text>
    //
    //                 <Text> – Монолітний пояс (план, перерізи, специфікація)</Text>
    //
    //                 <Text> – Конструктив по даху (план крокв та інших несучих елементів, вузли, специфікація на
    //                     пиломатеріали)
    //                 </Text>
    //
    //                 <Text> – План та розрізи по вхідних сходах, терасах.</Text>
    //
    //                 <Text> – Інші конструктивні елементи.</Text><br/>
    //
    //                 <Text onClick={() => {}} highlight={'ЗРАЗОК КОНСТРУКТИВНОГО РОЗДІЛУ'}><br/><br/></Text>
    //
    //                 <Text>Ціна робочого проекту : договірна та формується від складності проектування (рельєф, наявність
    //                     підвалу
    //                     та інших особливостей ділянки та завдання від Замовника)
    //                 </Text>
    //
    //                 <Text>Термін виконання робочого проекту: 6-10 тижнів</Text>
    //             </>)
    //     }
    // },
    '001': {
        children: () => {
            return (
                <>
                    <Text highlight={'Ескізні наміри забудови земельної ділянки'}
                          text={' – це документ, який необхідний для отримання\n' +
                              '                будівельного паспорта, а в подальшому отримання дозволу на будівництво.'}></Text><br/>

                    <Text>Також це початковий етап у проектуванні в якому формується майбутній образ будинку, а саме
                        планування
                        та
                        фасади.</Text><br/>

                    <Text>В ескізні наміри забудови входять наступні креслення:</Text>
                    <Text> – Загальні дані ( короткий опис про будинок – техніко-економічні показники, інформація про
                        основні
                        матеріали для будівництва)</Text>
                    <Text> – Схема генерального плану території (розташування будинку на ділянці, виконане на оновленій
                        геодезичній
                        зйомці)</Text>
                    <Text> – Фасади</Text>
                    <Text> – Плани поверхів. Експлікація приміщень</Text><br/>
                    <a href={'/eskiz.pdf'} download>
                        <Text highlight={'Приклад'}>
                        </Text>
                    </a>

                    <Text>Термін виконання ескізного проекту : 1-2 тижні (залежить від складності
                        проектування)</Text><br/>

                    <Text>Ціна : 3000-6000 грн.</Text><br/></>)
        }
    },
    '002': {
        children: () => {
            return (
                <>
                    <Text prefix={'В '} highlight={'Архітектурний розділ'} text={' входять наступні креслення:'}></Text>

                    <Text> – Загальні дані ( короткий опис про будинок – техніко-економічні показники, інформація про
                        основні
                        матеріали для будівництва)</Text>

                    <Text> – Схема генерального плану території (розташування будинку на ділянці, під’їзд до будинку та
                        пішохідні
                        шляхи)</Text>

                    <Text> – Архітектурні плани поверхів, з розташуванням меблів</Text>

                    <Text> – Архітектурні розрізи по будівлі.</Text>

                    <Text> – Фасади з чотирьох сторін.</Text>

                    <Text> – План даху.</Text>

                    <Text> – Кладочні плани (потрібні для мурування стін та перегородок, розміри прорізів для вікон та
                        дверей,
                        специфікація цегли)</Text>

                    <Text> – Розгортки вентиляційних та димових каналах (розміри вентиляційних та димових каналів,
                        висоти).</Text>

                    <Text> – Експлікація підлог.</Text>

                    <Text> – Специфікація дверних та віконних заповнень.</Text>

                    <Text> – Паспорт опорядження фасадів.</Text>

                    <Text> – Таблиця кольорів опорядження фасадів ( площі матеріалів та кількість декоративних
                        елементів)</Text><br/>

                    <Text onClick={() => {
                    }} highlight={'ЗРАЗОК АРХІТЕКТУРНОГО РОЗДІЛУ'}></Text><br/></>)
        }
    },
    '003': {
        children: () => {
            return (<><Text highlight={'Конструктивний розділ'}
                            text={' – визначає основні несучі конструктивні елементи будинку\n' +
                                '                (фундамент,\n' +
                                '                перекриття, перемички, дах і т.п.) з усім переліком матеріалів та специфікаціями.'}></Text><br/>

                <Text>В Конструктивний розділ входять наступні креслення:</Text>

                <Text> – План фундаментів (розрізи, схеми армування, вузли, витрати бетону та арматури)</Text>

                <Text> – Креслення колон (розрізи, схеми армування, вузли, витрати бетону та арматури)</Text>

                <Text> – План перемичок (перетини, витрати бетону та арматури)</Text>

                <Text> – Перекриття (план розташування плит перекриття, план монолітного перекриття, розрізи,
                    специфікація
                    матеріалу)</Text>

                <Text> – Сходи (опалубочний план сходів, розрізи, схеми армування, специфікація)</Text>

                <Text> – Монолітний пояс (план, перерізи, специфікація)</Text>

                <Text> – Конструктив по даху (план крокв та інших несучих елементів, вузли, специфікація на
                    пиломатеріали)</Text>

                <Text> – План та розрізи по вхідних сходах, терасах.</Text>

                <Text> – Інші конструктивні елементи.</Text><br/>

                <Text>ЗРАЗОК КОНСТРУКТИВНОГО РОЗДІЛУ</Text><br/>

                <Text>Ціна робочого проекту : договірна та формується від складності проектування (рельєф, наявність
                    підвалу
                    та інших особливостей ділянки та завдання від Замовника)</Text>

                <Text>Термін виконання робочого проекту: 6-10 тижнів</Text></>)
        }
    },
    '004': {
        children: () => {
            return (<></>)
        }
    },
    '005': {
        children: () => {
            return (<></>)
        }
    },
}

function ServicesScreen({ isHeaderCollapsed, isServicesVisible, onScrollToContacts, onScrollToPrices }) {
    const [services, setServices] = useState(SERVICES);
    const [visibleDetails, setVisibleDetails] = useState({
        '000': false,
        '001': false,
        '002': false,
        '003': false,
        '004': false,
    })

    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        // for (let i = 0; i < services.length; i++) {
        //     setTimeout(() => {
        //         const newServices = [...services];
        //         newServices[i].isVisible = true;
        //         setServices(newServices);
        //     }, 100 * i);
        // }
        setTimeout(() => {
            setIsVisible(true);
        })

    }, []);

    const onCardClick = (id) => {

        if (id === '000' || id === '005') {
            return;
        }

        if (id === '004') {
            return onScrollToContacts();
        }

        const newVisibleDetails = {
            ...visibleDetails,
            [id]: !visibleDetails[id]
        }
        setVisibleDetails(newVisibleDetails);
    }

    return (
        <>
            <Header isCollapsed={isHeaderCollapsed} onScrollToContacts={onScrollToContacts}
                    onScrollToPrices={onScrollToPrices}/>
            <Page>
                <main className={'article'}>
                    <Text text={'Наші послуги'} type={TEXT_TYPES.header} isVisible={isVisible}/>
                    <Text
                        highlight={'Важливо!'}
                        text={' Будь-які проектні рішення обумовленні інженерними вишукуваннями.'}
                        type={TEXT_TYPES.article} isVisible={isVisible}/>
                    <Text text={'Топографо-геодезичні вишукування визначають рельєф, межі, розташування будівель та ' +
                        'комунікацій, зони обмеження;'} isVisible={isVisible}/>
                    <Text text={'Інженерно-геологічні вишукування - тип ґрунту, його міцність, ' +
                        'глибину залягання, рівень ґрунтових вод, ймовірність просідання, зсувів, підтоплення.'}
                          isVisible={isVisible}/>
                    <Text text={'Також геологічні вишукування визначають категорію складності геологічних умов, ' +
                        'що оприділяє процедуру отримання дозвільних документів.'} type={TEXT_TYPES.article}
                          isVisible={isVisible}/>
                    <div className={'section-container column items-left'}>
                        {
                            services.map((item, index) => {
                                return (
                                    <div style={{ display: "flex", justifyContent: "center", width: "100%", textAlign: "center" }} key={index}
                                         className={index !== 0 && index !== 3 ? `service-card-expand-wrapper ${visibleDetails[item.id] ? 'active' : ''}` : ''}>
                                        <ServicesCard
                                            isVisible={isVisible}
                                            index={index}
                                            isActive={visibleDetails[item.id]}
                                            onClick={() => onCardClick(item.id)}
                                            isFullScreen
                                            key={item.id}
                                            text={item.description}
                                            highlight={item.title}
                                            imgSrc={require(`../../../assets/services/${item.src}`)}
                                            {...item}
                                        />
                                        <div
                                            className={visibleDetails[item.id] ? 'service-item-details visible' : 'service-item-details'}>
                                            {SERVICES_DETAILS[item.id].children()}
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    {/*<ModalFullScreenPage isVisible={modalData} title={modalData?.title}></ModalFullScreenPage>*/}
                    {/*<p>Звертайтеся і ми станемо надійними партнерами!</p>*/}
                    {/*<div style={{ padding: '30px 0' }}>*/}
                    {/*    <Button*/}
                    {/*        className="mr-1 btn"*/}
                    {/*        color="danger"*/}
                    {/*        href="#"*/}
                    {/*        target="_blank"*/}
                    {/*    >*/}
                    {/*        /!*<i className="nc-icon nc-spaceship"></i>*!/*/}
                    {/*        Дивитися Портфоліо*/}
                    {/*    </Button>*/}
                    {/*</div>*/}
                </main>
            </Page>
            <ContactsPage/>
        </>
    );
}

export default memo(ServicesScreen);
